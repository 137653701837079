import { request, noTimeOutReq } from '@/utils/request.js'

//获取local项目配置
export function localPlatformConfig () {
  return request({
    url: `/server/web/config/platform/local/list`,
    method: 'post',
  })
}

//更新local项目配置
export function updateLocalPlatformConfig (data) {
  return request({
    url: `/server/web/config/platform/local/update`,
    method: 'post',
    data
  })
}
