<template>
  <div>
    <el-table
      :header-cell-style="{ background: '#DFE6EC' }"
      header-align="center"
      border
      stripe
      ref="localPlatformConfigList"
      :data="localPlatformConfigList"
      tooltip-effect="dark"
      style="width: 100%"
      v-loading="loadingTable"
    >
      <el-table-column
        align="center"
        prop="configId"
        width="100"
        label="主键id"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="code"
        label="平台唯一编码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="enable"
        width="100"
        label="是否启用"
      >
        <template slot-scope="scope">{{
          !!scope.row.enable ? '启用' : '不启用'
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="protocol"
        width="100"
        label="协议"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="port"
        width="100"
        label="访问端口"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="ip"
        label="平台ip"
      ></el-table-column>
      <!-- <el-table-column align="center" prop="lastHeart" label="最后一次心跳时间戳"></el-table-column>
      <el-table-column align="center" prop="logo" label="logo地址"></el-table-column>
      <el-table-column align="center" prop="mac" label="平台mac"></el-table-column>-->
      <el-table-column
        align="center"
        prop="platformName"
        label="内容"
      ></el-table-column>
      <!-- <el-table-column align="center" prop="status" label="状态字段"></el-table-column> -->
      <!-- <el-table-column
        align="center"
        prop="studentName"
        label="学生端名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="teacherName"
        label="监考端名称"
      ></el-table-column> -->
      <el-table-column align="center" label="服务类型" width="150">
        <template slot-scope="scope">{{
          scope.row.type | typeFilters
        }}</template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            v-throttle
            size="mini"
            type="primary"
            @click="editBtn(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            v-throttle
            size="mini"
            @click="viewBtn(scope.$index, scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>-->
    <!-- 弹框 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogEdit"
      :before-close="
        () => {
          this.resetForm('editList')
        }
      "
    >
      <el-form
        :rules="rules"
        :model="editList"
        ref="editList"
        label-width="100px"
      >
        <el-form-item label="主键id">
          <el-input disabled v-model="editList.configId"></el-input>
        </el-form-item>
        <el-form-item label="平台唯一编码">
          <el-input disabled v-model="editList.code"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select
            disabled
            style="width: 100%"
            v-model="editList.enable"
            placeholder="请选择活动区域"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="不启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务类型">
          <el-select
            disabled
            style="width: 100%"
            v-model="editList.type"
            placeholder="请选择活动区域"
          >
            <el-option label="前端服务" :value="1"></el-option>
            <el-option label="后端API" :value="2"></el-option>
            <el-option label="对象存储" :value="4"></el-option>
            <el-option label="FTP服务" :value="5"></el-option>
            <el-option label="MQTT服务" :value="7"></el-option>
            <el-option label="流媒体服务" :value="8"></el-option>
            <el-option label="流媒体HTTP服务" :value="9"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议" prop="protocol">
          <el-input v-model="editList.protocol"></el-input>
        </el-form-item>
        <el-form-item label="平台ip" prop="ip">
          <el-input v-model="editList.ip"></el-input>
        </el-form-item>
        <el-form-item label="访问端口" prop="port">
          <el-input v-model="editList.port"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="platformName">
          <el-input v-model="editList.platformName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="学生端名称" prop="studentName">
          <el-input v-model="editList.studentName"></el-input>
        </el-form-item>
        <el-form-item label="监考端名称" prop="teacherName">
          <el-input v-model="editList.teacherName"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button v-throttle  size="medium" @click="dialogEdit = false">取 消</el-button> -->
        <el-button v-throttle size="medium" @click="resetForm('editList')"
          >取 消</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="updateLocalPlatformConfigFunc('editList')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog title="查看" :visible.sync="dialogView">
      <el-form
        :model="viewList"
        ref="viewList"
        class="viewClass"
        label-width="100px"
      >
        <el-form-item label="主键id">
          <el-input disabled v-model="viewList.configId"></el-input>
        </el-form-item>
        <el-form-item label="平台唯一编码">
          <el-input disabled v-model="viewList.code"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select
            style="width: 100%"
            disabled
            v-model="viewList.enable"
            placeholder="请选择活动区域"
          >
            <el-option label="启用" :value="1"></el-option>
            <el-option label="不启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务类型">
          <el-select
            style="width: 100%"
            disabled
            v-model="viewList.type"
            placeholder="请选择活动区域"
          >
            <el-option label="前端服务" :value="1"></el-option>
            <el-option label="后端API" :value="2"></el-option>
            <el-option label="对象存储" :value="4"></el-option>
            <el-option label="FTP服务" :value="5"></el-option>
            <el-option label="MQTT服务" :value="7"></el-option>
            <el-option label="流媒体服务" :value="8"></el-option>
            <el-option label="流媒体HTTP服务" :value="9"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="协议">
          <el-input disabled v-model="viewList.protocol"></el-input>
        </el-form-item>
        <el-form-item label="访问端口">
          <el-input disabled v-model="viewList.port"></el-input>
        </el-form-item>
        <el-form-item label="平台ip">
          <el-input disabled v-model="viewList.ip"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input disabled v-model="viewList.platformName"></el-input>
        </el-form-item>
        <el-form-item class="LH" label="最后一次心跳时间戳">
          <el-input disabled v-model="viewList.lastHeart"></el-input>
        </el-form-item>
        <el-form-item label="logo地址">
          <el-input disabled v-model="viewList.logo"></el-input>
        </el-form-item>
        <el-form-item label="平台mac">
          <el-input disabled v-model="viewList.mac"></el-input>
        </el-form-item>
        <el-form-item label="状态字段">
          <el-input disabled v-model="viewList.status"></el-input>
        </el-form-item>
        <!-- <el-form-item label="学生端名称">
          <el-input disabled v-model="viewList.studentName"></el-input>
        </el-form-item>
        <el-form-item label="监考端名称">
          <el-input disabled v-model="viewList.teacherName"></el-input>
        </el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  localPlatformConfig,
  updateLocalPlatformConfig,
} from '@/api/system/disposition.js'
export default {
  name: 'deviceManage',
  data() {
    return {
      localPlatformConfigList: [],
      dialogEdit: false,
      dialogView: false,
      editList: {},
      viewList: {},
      // 验证
      rules: {
        protocol: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
        ],
        port: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        ip: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        platformName: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
        ],
        studentName: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
        ],
        teacherName: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
        ],
      },

      loadingTable: false,
    }
  },
  mounted() {
    this.localPlatformConfigFunc()
  },
  methods: {
    // handleSizeChange (val) {
    //   this.tableDataPagination.pageSize = val
    //   this.tableDataPagination.pageIndex = 1
    // },
    // handleCurrentChange (val) {
    //   this.tableDataPagination.pageIndex = val
    // },
    localPlatformConfigFunc() {
      this.loadingTable = true
      localPlatformConfig().then((res) => {
        this.loadingTable = false
        if (res.success) {
          this.localPlatformConfigList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    viewBtn(i, val) {
      this.viewList = val
      this.dialogView = true
    },
    editBtn(i, val) {
      this.editList = JSON.parse(JSON.stringify(val))
      this.dialogEdit = true
    },
    updateLocalPlatformConfigFunc(key) {
      this.$refs[key].validate((valid) => {
        if (valid) {
          updateLocalPlatformConfig(this.editList).then((res) => {
            if (res.success && res.data) {
              this.$message.success('编辑成功')
              this.dialogEdit = false
              this.localPlatformConfigFunc()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    resetForm(key) {
      this.$refs[key].resetFields()
      this.editList = {}
      this.dialogEdit = false
    },
  },
  filters: {
    typeFilters(key) {
      switch (key) {
        case 1:
          return `前端服务`
        case 2:
          return `后端API`
        // case 3: return ``
        case 4:
          return `对象存储`
        case 5:
          return `FTP服务`
        // case 6: return ``
        case 7:
          return `MQTT服务`
        case 8:
          return `流媒体服务`
        case 9:
          return `流媒体HTTP服务`
        case 12:
          return `导出服务`
        default:
          return ``
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.viewClass .el-form-item {
  width: calc(50% - 10px) !important;
  display: inline-block;
}

::v-deep .LH label.el-form-item__label {
  line-height: 20px !important;
}
</style>
